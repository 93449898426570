<template>
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.0037 8.99833H3.99622" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.5022 14.0004C20.9886 14.0004 23.0041 16.016 23.0041 18.5023C23.0041 20.9886 20.9886 23.0042 18.5022 23.0042C16.0159 23.0042 14.0004 20.9886 14.0004 18.5023C14.0004 16.016 16.0159 14.0004 18.5022 14.0004" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.6741 22.0037H6.99747C5.33992 22.0037 3.99622 20.66 3.99622 19.0025V6.9975C3.99622 5.33995 5.33992 3.99625 6.99747 3.99625H19.0025C20.66 3.99625 22.0037 5.33995 22.0037 6.9975V15.6741" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.7242 16.2909V18.6834" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.7755 18.6833H18.7242" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.79681 12.4998C7.79681 12.5274 7.77442 12.5498 7.74679 12.5498C7.71916 12.5498 7.69677 12.5274 7.69677 12.4998C7.69677 12.4722 7.71916 12.4498 7.74679 12.4498" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.7468 12.4498C7.77443 12.4498 7.79683 12.4722 7.79683 12.4998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.2993 12.4998C11.2993 12.5274 11.2769 12.5498 11.2492 12.5498C11.2216 12.5498 11.1992 12.5274 11.1992 12.4998C11.1992 12.4722 11.2216 12.4498 11.2492 12.4498" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.2492 12.4498C11.2769 12.4498 11.2993 12.4722 11.2993 12.4998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.79681 15.501C7.79681 15.5287 7.77442 15.5511 7.74679 15.5511C7.71916 15.5511 7.69677 15.5287 7.69677 15.501C7.69677 15.4734 7.71916 15.451 7.74679 15.451" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.7468 15.451C7.77443 15.451 7.79683 15.4734 7.79683 15.501" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.2993 15.501C11.2993 15.5287 11.2769 15.5511 11.2492 15.5511C11.2216 15.5511 11.1992 15.5287 11.1992 15.501C11.1992 15.4734 11.2216 15.451 11.2492 15.451" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.2492 15.451C11.2769 15.451 11.2993 15.4734 11.2993 15.501" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.7997 12.4998C14.7997 12.5274 14.7773 12.5498 14.7497 12.5498C14.7221 12.5498 14.6997 12.5274 14.6997 12.4998C14.6997 12.4722 14.7221 12.4498 14.7497 12.4498" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.7497 12.4498C14.7774 12.4498 14.7998 12.4722 14.7998 12.4998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.79681 18.5023C7.79681 18.5299 7.77442 18.5523 7.74679 18.5523C7.71916 18.5523 7.69677 18.5299 7.69677 18.5023C7.69677 18.4747 7.71916 18.4523 7.74679 18.4523" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.7468 18.4523C7.77443 18.4523 7.79683 18.4747 7.79683 18.5023" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.2993 18.5023C11.2993 18.5299 11.2769 18.5523 11.2492 18.5523C11.2216 18.5523 11.1992 18.5299 11.1992 18.5023C11.1992 18.4747 11.2216 18.4523 11.2492 18.4523" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.2492 18.4523C11.2769 18.4523 11.2993 18.4747 11.2993 18.5023" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>