<template>
  <span 
    @click="closeModal"
    class="close-button absolute top-1 right-0 cursor-pointer"
  >
    <img :src="require('../assets/icons/close-icon.svg')" alt="">
  </span>
</template>

<script>
  export default {
    methods: {
      closeModal(){
        this.$emit('closeModal')
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>