<template>
  <form 
    v-click-outside="closeModalOutside" 
    class="mt-1 z-40 absolute ml-4 px-8 py-7 base-bg rounded zoom-big-enter" 
    @submit.prevent
  >
    <div class="relative">

      <closeModal @closeModal="closeModal" />
      
      <span class="block text-black text-lg text-left font-bold mb-3 leading-none">Date Filter</span>
      <span class="block text-black text-sm text-left mb-4 leading-none">Please select a date range</span>
      <v-date-picker
        v-model="localRange"
        mode="date"
        :masks="masks"
        :format="DatePickerFormat"
        :max-date='new Date()'
        is-range
      >
        <template v-slot="{ inputValue, inputEvents, isDragging }">
          <div class="flex flex-col sm:flex-row justify-start items-center">
            <div class="relative flex-grow">
              <calendar class="text-gray-600 w-6 h-full mx-4 absolute pointer-events-none"/>
              <input
                class="flex-grow pl-14 pr-2 py-3 bg-white border rounded base-border-color w-full font-bold text-sm"
                :class="isDragging ? 'text-gray-600' : 'text-black'"
                :value="inputValue.start"
                v-on="inputEvents.start"
              />
            </div>
            <span class="flex-shrink-0 m-2">
              <svg
                class="w-4 h-4 stroke-current text-gray-600"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
            <div class="relative flex-grow">
              <calendar class="text-gray-600 w-6 h-full mx-4 absolute pointer-events-none"/>
              <input
                class="flex-grow pl-14 pr-2 py-3 bg-white border rounded base-border-color w-full font-bold text-sm"
                :class="isDragging ? 'text-gray-600' : 'text-black'"
                :value="inputValue.end"
                v-on="inputEvents.end"
              />
            </div>
          </div>
        </template>
      </v-date-picker>
    </div>
  </form>
</template>

<script>
  import calendar from '@/components/icons/calendar'
  import closeModal from '@/components/CloseModal'
  import { mapMutations } from 'vuex';

  export default {
    components: { calendar, closeModal },
    props:{
      isGlobal: {
        type: Boolean,
        default: true
      },
      range:{
        type: Object,
        default: () => {
          return {
            start: new Date(),
            end: new Date(),
          }
        }
      }
    },
    data() {
      return {
        DatePickerFormat: 'dd/MM/yyyy',
        masks: {
          input: 'DD-MM-YYYY',
        },
        localState: false
      };
    },
    computed: {
      localRange: {
        get() {
          return this.range
        },
        set(newValue) {
          this.setDateRange(newValue)
        }
      }
    },
    methods: {
      ...mapMutations({
        setDate: 'search/setDate'
      }),
      setDateRange(event){
        if(this.isGlobal){
          this.setDate([
            this.$moment(event.start).unix(), 
            this.$moment(event.end).unix()
          ])
        }else{
          this.$emit('dates', {
            start: event.start,
            end: event.end
          })
        }
        
      },
      closeModal(){
        this.$emit('closeModal')
      },
      closeModalOutside(){
        if(this.localState) this.$emit('closeModal')
        this.localState = true
      }
    },
  };
</script>

<style lang="scss" scoped>

</style>